import React, { useState } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from "@/components/ui/tabs";

// Demo videos organized by language
const demoVideos: LanguageVideos[] = [
  {
    languageCode: 'en',
    languageName: 'English',
    videos: [
      {
        id: 'getting-started-en',
        title: 'How to set the API key and start chatting with the assistant',
        description: 'Learn the basics of the Botnexus platform',
        embedUrl: 'https://app.supademo.com/embed/cm7jjio6107mcddumzn840arr?embed_v=2',
      },
      // Add more English videos as needed
    ]
  },
  {
    languageCode: 'he',
    languageName: 'Hebrew',
    videos: [
      {
        id: 'getting-started-he',
        title: 'איך להגדיר מפתח והתחלת שיחה',
        description: 'הוראות כיצד להגדיר את המפתח + לבחור עוזר ולהדביק אותו + לשאול שאלה',
        embedUrl: 'https://app.supademo.com/embed/cm7jjio6107mcddumzn840arr?embed_v=2',
      },
      // Add more Spanish videos as needed
    ]
  },
  // Add more languages as needed
];

interface SupademoVideo {
  id: string;
  title: string;
  description: string;
  embedUrl: string;
  topic?: string;
}

interface LanguageVideos {
  languageCode: string;
  languageName: string;
  videos: SupademoVideo[];
}

const SupademoVideos = () => {
  const [activeVideo, setActiveVideo] = useState<string | null>(
    demoVideos[1]?.videos[0]?.id || null
  );

  const toggleVideo = (videoId: string) => {
    setActiveVideo(activeId => activeId === videoId ? null : videoId);
  };

  return (
    <section className="supademo-videos mb-4 bg-gray-50 p-6 rounded-lg border border-gray-200">
      <h2 className="text-2xl font-semibold">Tutorial Videos</h2>
      <p className="text-gray-700 mb-4">
        Watch our interactive Supademo videos for a guided tour of key features.
      </p>

      <Tabs defaultValue={demoVideos[0]?.languageCode || "en"} className="w-full">
        <TabsList className="mb-4">
          {demoVideos.map(language => (
            <TabsTrigger key={language.languageCode} value={language.languageCode}>
              {language.languageName}
            </TabsTrigger>
          ))}
        </TabsList>

        {demoVideos.map(language => (
          <TabsContent key={language.languageCode} value={language.languageCode}>
            <div className="space-y-4">
              {language.videos.map(video => (
                <div key={video.id} className="border border-gray-200 rounded-lg overflow-hidden">
                  <div
                    className="p-4 bg-white cursor-pointer flex justify-between items-center"
                    onClick={() => toggleVideo(video.id)}
                  >
                    <div>
                      <h3 className="text-lg font-medium">{video.title}</h3>
                      <p className="text-gray-600">{video.description}</p>
                    </div>
                    <span className="text-gray-400">
                      {activeVideo === video.id ? '▼' : '▶'}
                    </span>
                  </div>

                  {activeVideo === video.id && (
                    <div className="relative w-full" style={{ paddingTop: '56.25%' }}>
                      <iframe
                        src={video.embedUrl}
                        loading="lazy"
                        title={video.title}
                        allow="clipboard-write"
                        frameBorder="0"
                        allowFullScreen
                        data-webkit-allowfullscreen="true"
                        data-moz-allowfullscreen="true"
                        style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </TabsContent>
        ))}
      </Tabs>
    </section>
  );
};

export default SupademoVideos;