import React from 'react';

const SupportContact = () => {
  return (
    <section className="support-contact mb-8">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">Need Help?</h2>
      <div className="bg-white rounded-lg shadow-xl p-8">
        <div className="flex items-center justify-between">
          <div className="max-w-lg">
            <h3 className="text-2xl font-bold text-gray-900 mb-3">Join Our Discord Community</h3>
            <p className="text-gray-700 text-lg">
              Get real-time support, connect with other educators, and stay updated on new features!
            </p>
          </div>
          <a
            href="https://discord.gg/PWTgg36x"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center px-8 py-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors text-lg font-semibold"
          >
            Join Discord
          </a>
        </div>
        <div className="mt-8 border-t pt-8">
          <h3 className="text-2xl font-bold text-gray-900 mb-3">Email Support</h3>
          <p className="text-gray-700 text-lg">
            For direct assistance, email us at{' '}
            <a href="mailto:office@botnexus.ai" className="text-blue-600 hover:underline">
              office@botnexus.ai
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default SupportContact;