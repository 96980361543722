import { Routes, Route, Navigate } from 'react-router-dom'
import { DashboardLayout } from '../layout/DashboardLayout'
import { DashboardPage } from '@/pages/dashboard/DashboardPage'
import { ChatPage } from '@/pages/chat/ChatPage'
import { SettingsPage } from '@/pages/settings/SettingsPage'
import HelpPage from '@/pages/help/HelpPage'
import { LoadingScreen } from '@/components/ui/loading-screen'
import { LoginPage } from '@/pages/auth/LoginPage'
import { SignupPage } from '@/pages/auth/SignupPage'
import { VerifyEmailPage } from '@/pages/auth/VerifyEmailPage'
import { MagicLinkPage } from '@/pages/auth/MagicLinkPage'
import { ErrorBoundary } from './ErrorBoundary'
import { useAuth } from './AuthProvider'
import { UninvitedPage } from '@/pages/auth/UninvitedPage'
import { WelcomePage } from '@/pages/dashboard/WelcomePage'
import { NotFoundPage } from '@/pages/NotFoundPage'

function PrivateRoute({ children }: { children: React.ReactNode }) {
  const { user, loading, error } = useAuth()

  if (loading) return <LoadingScreen />
  if (error) return null // ApiErrorPage is handled by AuthProvider
  if (!user) {
    return <Navigate to="/login" replace />
  }

  return <>{children}</>
}

export function AppRoutes() {
  const { user } = useAuth();

  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/login" element={
          user ? <Navigate to="/dashboard/chat" replace /> : <LoginPage />
        } />
        <Route path="/signup" element={
          user ? <Navigate to="/dashboard/chat" replace /> : <SignupPage />
        } />
        <Route path="/magic-link" element={
          user ? <Navigate to="/dashboard/chat" replace /> : <MagicLinkPage />
        } />
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route path="/" element={
          user ? (
            <Navigate to="/dashboard/chat" replace />
          ) : (
            <WelcomePage />
          )
        } />
        <Route path="/uninvited" element={<UninvitedPage />} />
        <Route element={
          <PrivateRoute>
            <DashboardLayout />
          </PrivateRoute>
        }>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/dashboard/chat" element={<ChatPage />} />
          <Route path="/dashboard/settings" element={<SettingsPage />} />
          <Route path="/dashboard/help" element={<HelpPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </ErrorBoundary>
  );
}
