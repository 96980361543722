import React from 'react';

const FAQ = () => {
  const faqs = [
    {
      question: 'What is Botnexus?',
      answer:
        'Botnexus is an AI-powered educational platform designed to transform the learning experience for educators and students by providing custom AI tools.',
    },
    {
      question: 'How do I invite students?',
      answer:
        'You can invite students through the platform by navigating to the "Students" section and following the invitation process.',
    },
    {
      question: 'Can I track student progress?',
      answer:
        'Yes, Botnexus provides features for educators to monitor student progress and identify areas where students might need additional support.',
    },
    {
      question: 'Is there a community for Botnexus users?',
      answer: 'Yes, we have an active Discord community where you can get real-time support, discuss features, share best practices, and collaborate with other educators. You can join using the link provided in the Support section.',
    }
  ];

  return (
    <section className="faq mb-8">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">Frequently Asked Questions</h2>
      <div className="bg-white rounded-lg shadow-md p-6">
        {faqs.map((faq, index) => (
          <div key={index} className="mb-4">
            <h3 className="text-xl font-bold text-gray-900 mb-2">{faq.question}</h3>
            <p className="text-gray-700">{faq.answer}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;