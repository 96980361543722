import { ChatContainer } from '@/components/chat/ChatContainer';

export function ChatPage() {
  return (
    <div className="flex flex-col flex-grow p-4"> {/* Allow page to grow vertically */}
      <h2 className="text-3xl font-bold tracking-tight">Smart AI Assistants</h2>
      <ChatContainer />
    </div>
  );
}
