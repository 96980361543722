import { Logo } from "../ui/logo"
import { NetworkBackground } from "../ui/NetworkBackground"

interface WelcomeLayoutProps {
  children: React.ReactNode
}

export function WelcomeLayout({ children }: WelcomeLayoutProps) {
  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 via-white to-blue-50 dark:from-gray-900 dark:via-gray-900 dark:to-gray-800 relative overflow-hidden">
      <NetworkBackground />
      <div className="relative">
        <header className="border-b bg-white/80 backdrop-blur-sm dark:bg-gray-900/80">
          <div className="container flex h-16 items-center px-4 sm:px-6 lg:px-8">
            <Logo />
          </div>
        </header>
        <main className="container mx-auto px-4 sm:px-6 lg:px-8 relative">
          {children}
        </main>
      </div>
    </div>
  )
}