import React from 'react';
import SearchHelp from './SearchHelp';
import HelpContent from './HelpContent';
import SupademoVideos from './SupademoVideos';
import SupportContact from './SupportContact';
import FAQ from './FAQ';

const HelpPage = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">Help Center</h1>
      <p className="text-lg text-gray-600 mb-8">
        Welcome to the Botnexus Help Center. Find answers to your questions, explore our features, and get the support you need.
      </p>
      {/* <SearchHelp /> */}
      <SupportContact />
      {/* <HelpContent /> */}
      <SupademoVideos />
      <FAQ />
    </div>
  );
};

export default HelpPage;