import { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { AuthLayout } from '@/components/auth/AuthLayout';
import { LoadingScreen } from '@/components/ui/loading-screen';
import { NetworkBackground } from '@/components/ui/NetworkBackground';

// Storage key for email persistence
const EMAIL_STORAGE_KEY = 'magic_link_email';

export function MagicLinkPage() {
  const [email, setEmail] = useState(() => {
    // Try to get email from localStorage
    return localStorage.getItem(EMAIL_STORAGE_KEY) || '';
  });
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<'idle' | 'sent' | 'error' | 'verifying'>('idle');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // Check for authentication token in URL on component mount
  useEffect(() => {
    const handleTokenVerification = async () => {
      // If we're already in a loading or sent state, don't proceed
      if (loading || status === 'sent') return;

      // Check if we have a hash in the URL (token from magic link)
      if (location.hash) {
        setStatus('verifying');
        setLoading(true);
        
        try {
          console.log('Detected hash in URL:', location.hash);
          
          // Parse the hash to check for access_token
          const hashParams = new URLSearchParams(location.hash.substring(1));
          const accessToken = hashParams.get('access_token');
          const tokenType = hashParams.get('token_type');
          const type = hashParams.get('type');
          
          if (accessToken && tokenType === 'bearer' && type === 'magiclink') {
            console.log('Found magic link token in URL');
          }
          
          // The Supabase client should automatically handle the token
          // due to detectSessionInUrl: true in the configuration
          const { data, error } = await supabase.auth.getSession();
          
          if (error) throw error;
          
          if (data.session) {
            // Successfully authenticated
            console.log('Magic link authentication successful');
            // Redirect to dashboard
            navigate('/dashboard/chat');
            return;
          } else {
            // If getSession didn't work but we have an access token, try setting the session manually
            if (accessToken) {
              console.log('Attempting to set session manually with token');
              try {
                // This is a fallback in case automatic detection doesn't work
                const { data: sessionData, error: sessionError } = await supabase.auth.setSession({
                  access_token: accessToken,
                  refresh_token: '',
                });
                
                if (sessionError) throw sessionError;
                
                if (sessionData.session) {
                  console.log('Manual session setting successful');
                  navigate('/dashboard/chat');
                  return;
                }
              } catch (sessionError: any) {
                console.error('Error setting session manually:', sessionError);
                throw sessionError;
              }
            }
          }
          
          // If we get here, we couldn't authenticate with the token
          throw new Error('Could not authenticate with the provided token');
        } catch (error: any) {
          console.error('Error verifying magic link:', error);
          setStatus('error');
          setErrorMessage('Failed to verify magic link. Please try again.');
        } finally {
          setLoading(false);
        }
      }
    };

    handleTokenVerification();
  }, [location, navigate, loading, status]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email) {
      setErrorMessage('Please enter your email');
      setStatus('error');
      return;
    }
    
    setLoading(true);
    
    try {
      // Save email to localStorage for persistence
      localStorage.setItem(EMAIL_STORAGE_KEY, email);
      
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          // Redirect to the root of the application instead of /magic-link
          // This matches the expected URL format for magic links
          emailRedirectTo: window.location.origin,
        }
      });
      
      if (error) throw error;
      
      setStatus('sent');
      setErrorMessage('');
    } catch (error: any) {
      setStatus('error');
      setErrorMessage(error.message || 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout>
      <NetworkBackground />
      <div className="min-h-screen from-primary/5 to-background">
        <div className="container mx-auto px-4 py-16">
          <div className="mb-16 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              Magic Link Authentication
            </h1>
            <p className="mt-4 text-xl text-muted-foreground">
              Access your account with a secure link sent to your email
            </p>
          </div>
          
          <div className="mx-auto max-w-md">
            <Card>
              <CardHeader>
                <CardTitle>Login with Magic Link</CardTitle>
                <CardDescription>
                  {status === 'sent'
                    ? 'Check your email'
                    : 'We\'ll send a secure login link to your email'}
                </CardDescription>
              </CardHeader>
              <CardContent>
                {status === 'sent' ? (
                  <div className="space-y-4 text-center">
                    <div className="p-6">
                      <h3 className="text-lg font-medium">Magic link sent!</h3>
                      <p className="mt-2 text-muted-foreground">
                        Please check your email for a link to sign in.
                      </p>
                    </div>
                    <Link to="/login">
                      <Button variant="outline" className="w-full">
                        Back to Login
                      </Button>
                    </Link>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="space-y-2">
                      <Input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    {status === 'error' && errorMessage && (
                      <p className="text-sm text-red-500">{errorMessage}</p>
                    )}
                    <Button
                      type="submit"
                      className="w-full"
                      disabled={loading}
                    >
                      {loading ? 'Sending...' : 'Send Magic Link'}
                    </Button>
                    <p className="text-center text-sm text-muted-foreground">
                      <Link
                        to="/login"
                        className="text-primary underline-offset-4 hover:underline"
                      >
                        Back to Login
                      </Link>
                    </p>
                  </form>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
      {loading && <LoadingScreen />}
    </AuthLayout>
  );
}