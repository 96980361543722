import { useState } from 'react'
import { config } from '@/lib/config'
import { useAuth } from '@/components/auth/AuthProvider'
import MarkdownIt from 'markdown-it'
import { ChatFrame } from './ChatFrame'

export type ExtendedAgent = {
  id: string
  originalId: string
  name: string
  description: string
  isActive: boolean
}

// A simple encoding function that takes the prefix before the first "."
function encodeBotId(botId: string): string {
  return botId.split('.')[0];
}

export function ChatContainer() {
  const { userBotData } = useAuth()
  const mdParser = new MarkdownIt({ breaks: true })

  const [chatParams, setChatParams] = useState<{ agentId: string, sessionId: string, originalId: string } | null>(null)
  const [agentName, setAgentName] = useState<string | null>(null)
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)

  // Map raw bot associations into agents
  const rawAgents: ExtendedAgent[] = userBotData && userBotData.bot_associations
    ? (userBotData.bot_associations as {
        bot_id: string
        description: string
        name: string
        creation_date: string
      }[]).map((bot) => ({
        id: encodeBotId(bot.bot_id),
        originalId: bot.bot_id,
        name: bot.name,
        description: bot.description,
        isActive: true
      }))
    : []

  // Deduplicate agents by their originalId and sort them
  const agents: ExtendedAgent[] = Array.from(new Map(rawAgents.map(agent => [agent.originalId, agent])).values())
    .sort((a, b) => a.originalId.localeCompare(b.originalId))
  const groupedAgents = agents.reduce((acc, agent) => {
    const prefix = agent.originalId.split('.')[0];
    const groupName = prefix.charAt(0).toUpperCase() + prefix.slice(1);
    if (!acc[groupName]) {
      acc[groupName] = [];
    }
    acc[groupName].push(agent);
    return acc;
  }, {} as { [key: string]: ExtendedAgent[] });

  // When an agent is clicked, update the chat URL and agent name in the state
  const handleAgentClick = (agent: ExtendedAgent) => {
    console.log('[HOST] User Clicks on Assistant:', agent)
    const sessionId = `chat-${agent.id}-${Date.now()}`
    console.log('USER CLICKS TO OPEN CHAT')
    setChatParams({ agentId: agent.id, sessionId, originalId: agent.originalId,  })
    setAgentName(agent.name)
  }

  // Handler to close the chat and return to the agent selection UI
  const handleCloseChat = () => {
    setChatParams(null)
    setAgentName(null)
    setIsFullScreen(false)
  }

  // // Toggle full screen mode for the chat
  // const handleToggleFullScreen = () => {
  //   setIsFullScreen(prev => !prev)
  // }

  // If a chat is active, show the chat view with controls; otherwise, show the agent selection
  if (chatParams && agentName) {
    return (
      <div className={isFullScreen ? "fixed inset-0 z-50 bg-white flex flex-col" : "p-4 flex flex-col flex-grow"}> {/* Allow container to grow vertically */}
        <div className="flex justify-end items-center space-x-2 mb-2">
          {/* "Open in New Tab" button - made more prominent and moved left */}
          <button
            onClick={() => {
                const newSrc = `${config.chatAppURL}?id=${chatParams.agentId}&agentId=${encodeURIComponent(chatParams.originalId)}&sessionId=${chatParams.sessionId}&botIdPrefix=${chatParams.agentId}`;
                const newWindow = window.open(newSrc, '_blank');
                if (newWindow) {
                  newWindow.document.body.id = encodeBotId(userBotData.bot_associations[0].bot_id);
                }
              }}
            className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 animate-pulse" // Added styling and animation
          >
            Open in New Tab
          </button>
          {/* "Close Chat" button */}
          <button
            onClick={handleCloseChat}
            className="px-3 py-1 bg-gray-200 rounded hover:bg-gray-300" // Adjusted hover
          >
            Close Chat
          </button>
          {/* <button
            onClick={handleToggleFullScreen}
            className="px-3 py-1 bg-gray-200 rounded"
          >
            {isFullScreen ? "Exit Full Screen" : "Full Screen"}
          </button> */}
        </div>
        {/* Updated ChatFrame component using agentId, agentName, and sessionId */}
        <ChatFrame agentId={chatParams.agentId} agentName={agentName} sessionId={chatParams.sessionId} />
      </div>
    )
  }

    return (
      <div className="p-4">
        <h3 className="text-la font-italic mb-5">The following Agents / Assistants are available for you to use</h3>
        {Object.entries(groupedAgents).map(([groupName, groupAgents]) => (
          <div key={groupName} className="mb-8">
            <h4 className="text-2xl font-bold mb-4">{groupName}</h4>
            <div className={`grid gap-4 ${groupAgents.length === 1 ? 'grid-cols-1' : 'grid-cols-1 md:grid-cols-2'}`}>
              {groupAgents.map(agent => (
                <div
                  key={agent.originalId}
                  className="p-4 rounded border cursor-pointer hover:shadow-lg w-full"
                  onClick={() => handleAgentClick(agent)}
                >
                  <div className="mb-4">
                    <h4 className="text-lg font-semibold">{agent.name}</h4>
                    <div
                      className="text-md text-gray-600 mt-2"
                      dangerouslySetInnerHTML={{
                        __html: mdParser.render(agent.description || '')
                          .replace(/<p>/g, '<p class="mb-4">')
                      }}
                    />
                  </div>
                  {/* <div className="pt-2 border-t mt-2">
                    <p className="text-sm text-gray-500">Usage stats/graph placeholder</p>
                  </div> */}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    )
}
