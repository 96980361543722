import { Button } from "../../components/ui/button";
import { useNavigate, useLocation } from "react-router-dom";
import { WelcomeLayout } from "../../components/layout/WelcomeLayout";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "../../components/ui/card";
import { useEffect, useState } from "react";
import { supabase } from "@/lib/supabase";
import { LoadingScreen } from "@/components/ui/loading-screen";

export function WelcomePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  // Check for authentication token in URL on component mount
  useEffect(() => {
    const handleTokenVerification = async () => {
      // Check if we have a hash in the URL (token from magic link)
      if (location.hash) {
        setLoading(true);
        
        try {
          console.log('Detected hash in URL:', location.hash);
          
          // Parse the hash to check for access_token
          const hashParams = new URLSearchParams(location.hash.substring(1));
          const accessToken = hashParams.get('access_token');
          const tokenType = hashParams.get('token_type');
          const type = hashParams.get('type');
          
          if (accessToken && tokenType === 'bearer' && type === 'magiclink') {
            console.log('Found magic link token in URL');
          }
          
          // The Supabase client should automatically handle the token
          // due to detectSessionInUrl: true in the configuration
          const { data, error } = await supabase.auth.getSession();
          
          if (error) throw error;
          
          if (data.session) {
            // Successfully authenticated
            console.log('Magic link authentication successful');
            // Redirect to dashboard
            navigate('/dashboard/chat');
            return;
          } else {
            // If getSession didn't work but we have an access token, try setting the session manually
            if (accessToken) {
              console.log('Attempting to set session manually with token');
              try {
                // This is a fallback in case automatic detection doesn't work
                const { data: sessionData, error: sessionError } = await supabase.auth.setSession({
                  access_token: accessToken,
                  refresh_token: '',
                });
                
                if (sessionError) throw sessionError;
                
                if (sessionData.session) {
                  console.log('Manual session setting successful');
                  navigate('/dashboard/chat');
                  return;
                }
              } catch (sessionError: any) {
                console.error('Error setting session manually:', sessionError);
                throw sessionError;
              }
            }
          }
          
          // If we get here, we couldn't authenticate with the token
          console.error('Could not authenticate with the provided token');
        } catch (error: any) {
          console.error('Error verifying magic link:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    handleTokenVerification();
  }, [location, navigate]);
  return (
    <WelcomeLayout>
      {loading && <LoadingScreen />}
      <div className="space-y-8 sm:space-y-12 py-6 sm:py-8">
        {/* Hero Section with Animation and Login Card */}
        <div className="grid md:grid-cols-5 gap-6 sm:gap-8 items-start">
          <div className="md:col-span-3 text-left space-y-4 animate-fade-in">
            <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold tracking-tight bg-gradient-to-r from-orange-600 to-amber-600 bg-clip-text text-transparent">
              Welcome to Botnexus: Level Up Learning with AI
            </h2>
            <p className="text-lg sm:text-xl text-muted-foreground mt-2">
              Intelligent AI Tools to Transform Your Educational Experience.
            </p>
          </div>
          
          <Card className="md:col-span-2 animate-fade-in hover:shadow-lg transition-all duration-300">
            <CardHeader>
              <CardTitle className="text-xl sm:text-2xl text-center bg-gradient-to-r from-orange-600 to-amber-600 bg-clip-text text-transparent">
                Get Started Today
              </CardTitle>
              <CardDescription className="text-center">
                Join the future of education
              </CardDescription>
            </CardHeader>
            <CardContent className="space-y-4">
              <Button
                onClick={() => navigate("/login")}
                size="lg"
                className="w-full bg-gradient-to-r from-orange-600 to-amber-600 hover:from-orange-700 hover:to-amber-700 transition-all duration-300 shadow-md hover:shadow-lg"
              >
                Log In
              </Button>
              <Button
                onClick={() => navigate("/signup")}
                variant="outline"
                size="lg"
                className="w-full hover:bg-gradient-to-r hover:from-orange-600 hover:to-amber-600 hover:text-white transition-all duration-300"
              >
                Sign Up
              </Button>
            </CardContent>
          </Card>
        </div>

        {/* What is Botnexus Section */}
        <Card className="max-w-3xl mx-auto hover:shadow-lg transition-shadow duration-300">
          <CardHeader>
            <CardTitle>What is Botnexus?</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-muted-foreground text-lg leading-relaxed">
              Botnexus is your gateway to a new era of learning, offering custom AI-powered tools designed to empower both educators and students. We're revolutionizing education by creating personalized, engaging, and effective learning experiences powered by the latest in AI technology.
            </p>
          </CardContent>
        </Card>

        {/* Features Section */}
        <section className="grid gap-6 sm:gap-8 md:grid-cols-2">
          <Card className="hover:shadow-lg transition-all duration-300 hover:-translate-y-1">
            <CardHeader>
              <CardTitle className="text-xl sm:text-2xl bg-gradient-to-r from-orange-600 to-amber-600 bg-clip-text text-transparent">
                For Educators
              </CardTitle>
              <CardDescription>Simplify, Engage, and Innovate</CardDescription>
            </CardHeader>
            <CardContent>
              <ul className="space-y-4">
                <li className="flex flex-col gap-1">
                  <span className="font-semibold text-lg">Invite Your Whole Class</span>
                  <span className="text-muted-foreground">Invite your whole class to work with an agent you create.</span>
                </li>
                <li className="flex flex-col gap-1">
                  <span className="font-semibold text-lg">Student Progress Oversight</span>
                  <span className="text-muted-foreground">Keep track of student progress with comprehensive monitoring.</span>
                </li>
                <li className="flex flex-col gap-1">
                  <span className="font-semibold text-lg">Proactive Support</span>
                  <span className="text-muted-foreground">See who is being helped and who needs assistance.</span>
                </li>
                <li className="flex flex-col gap-1">
                  <span className="font-semibold text-lg">Student Success Focus</span>
                  <span className="text-muted-foreground">Ensure every student succeeds with personalized support.</span>
                </li>
              </ul>
            </CardContent>
          </Card>

          <Card className="hover:shadow-lg transition-all duration-300 hover:-translate-y-1">
            <CardHeader>
              <CardTitle className="text-2xl bg-gradient-to-r from-orange-600 to-amber-600 bg-clip-text text-transparent">
                For Students
              </CardTitle>
              <CardDescription>Learn Smarter, Not Harder</CardDescription>
            </CardHeader>
            <CardContent>
              <ul className="space-y-4">
                <li className="flex flex-col gap-1">
                  <span className="font-semibold text-lg">Unlock Your Potential</span>
                  <span className="text-muted-foreground">Access AI-powered learning companions tailored to your needs.</span>
                </li>
                <li className="flex flex-col gap-1">
                  <span className="font-semibold text-lg">Get Personalized Support</span>
                  <span className="text-muted-foreground">Receive instant feedback and practice exercises on demand.</span>
                </li>
                <li className="flex flex-col gap-1">
                  <span className="font-semibold text-lg">Master Complex Concepts</span>
                  <span className="text-muted-foreground">Learn through interactive dialogues and real-world examples.</span>
                </li>
                <li className="flex flex-col gap-1">
                  <span className="font-semibold text-lg">Build Confidence</span>
                  <span className="text-muted-foreground">Achieve excellence with adaptive learning support.</span>
                </li>
              </ul>
            </CardContent>
          </Card>
        </section>

        {/* Footer Section */}
        <section className="mt-12 sm:mt-16 space-y-8 sm:space-y-12">
          {/* Call to Action */}
          <Card className="text-center hover:shadow-lg transition-all duration-300">
            <CardHeader>
              <CardTitle className="text-2xl sm:text-3xl bg-gradient-to-r from-orange-600 to-amber-600 bg-clip-text text-transparent">
                Ready to Transform Your Educational Experience?
              </CardTitle>
              <CardDescription className="text-base sm:text-lg">
                Schedule a 60-minute demo to see how Botnexus can revolutionize your teaching and learning
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Button
                size="lg"
                className="bg-gradient-to-r from-orange-600 to-amber-600 hover:from-orange-700 hover:to-amber-700 transition-all duration-300 shadow-md hover:shadow-lg"
                onClick={() => window.open('https://calendly.com/office-botnexus/30min', '_blank')}
              >
                Book a Demo Meeting
              </Button>
            </CardContent>
          </Card>

          {/* Links Section */}
          <div className="grid sm:grid-cols-2 gap-6 sm:gap-8 py-6 sm:py-8 border-t">
            <div className="space-y-3 sm:space-y-4">
              <h3 className="font-semibold text-base sm:text-lg">Company</h3>
              <ul className="space-y-2 sm:space-y-3">
                <li><a href="https://botnexus.ai/about-us" className="text-muted-foreground hover:text-orange-600 transition-colors">About Us</a></li>
                <li><a href="https://botnexus.ai/contact" className="text-muted-foreground hover:text-orange-600 transition-colors">Contact</a></li>
              </ul>
            </div>
            {/* <div className="space-y-4">
              <h3 className="font-semibold text-lg">Resources</h3>
              <ul className="space-y-2">
                <li><a href="/blog" className="text-muted-foreground hover:text-orange-600 transition-colors">Blog</a></li>
                <li><a href="/documentation" className="text-muted-foreground hover:text-orange-600 transition-colors">Documentation</a></li>
                <li><a href="/support" className="text-muted-foreground hover:text-orange-600 transition-colors">Support</a></li>
              </ul>
            </div> */}
            <div className="space-y-4">
              <h3 className="font-semibold text-lg">Legal</h3>
              <ul className="space-y-2">
                <li><a href="https://botnexus.ai/privacy" className="text-muted-foreground hover:text-orange-600 transition-colors">Privacy Policy</a></li>
                <li><a href="https://botnexus.ai/terms" className="text-muted-foreground hover:text-orange-600 transition-colors">Terms of Service</a></li>
                {/* <li><a href="/security" className="text-muted-foreground hover:text-orange-600 transition-colors">Security</a></li> */}
              </ul>
            </div>
          </div>
          <div className="text-center text-muted-foreground text-sm">
            Copyright © {new Date().getFullYear()} BotNexus. All Rights Reserved.
          </div>
        </section>
      </div>
    </WelcomeLayout>
  );
}
