import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import { Input } from '@/components/ui/input';
import { AuthLayout } from '@/components/auth/AuthLayout';
import { AuthError } from '@supabase/supabase-js';
import GoogleIcon from '@/components/icons/GoogleIcon'
import { NetworkBackground } from '@/components/ui/NetworkBackground'

export function LoginPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();

  const handleGoogleSignIn = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: window.location.origin,
          queryParams: {
            access_type: 'offline',
            prompt: 'consent',
          }
        }
      })
      if (error) throw error
    } catch (error: any) {
      setError(error.message || 'Failed to sign in with Google')
    }
  }

  const handleMagicLinkSignIn = async () => {
    if (!email) {
      setError('Please enter your email')
      return
    }
    
    setLoading(true)
    setError(null)

    try {
      // Save email to localStorage for persistence
      localStorage.setItem('magic_link_email', email)
      
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          // Redirect to the root of the application instead of /magic-link
          // This matches the expected URL format for magic links
          emailRedirectTo: window.location.origin,
        }
      })
      if (error) throw error
      navigate('/magic-link')
    } catch (error: any) {
      setError(error.message || 'Failed to send magic link')
    } finally {
      setLoading(false)
    }
  }

  const handleEmailSignIn = async (e: React.FormEvent) => {
    e.preventDefault()
    setLoading(true)
    setError(null)

    try {
      const { error } = await supabase.auth.signInWithPassword({
        email,
        password
      })
      if (error) {
         throw error
      } else {
        navigate('/dashboard/chat');
      }
    } catch (error: any) {
      setError(error.message || 'Invalid email or password')
    } finally {
      setLoading(false)
    }
  }

  return (
    <AuthLayout>
      <NetworkBackground />
      <div className="min-h-screen from-primary/5 to-background">
        <div className="container mx-auto px-4 py-16">
          <div className="mb-16 text-center">
            <h1 className="text-2xl font-semibold tracking-tight">
              Welcome to Botnexus Platform
            </h1>
            <p className="mt-4 text-xl text-muted-foreground">
              Your AI-powered educational platform for seamless learning and collaboration
            </p>
          </div>
  
          <div className="mx-auto max-w-md">
            <Card>
              <CardHeader>
                <CardTitle>Sign In</CardTitle>
                <CardDescription>Access your Botnexus Platform</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="space-y-4">
                  <Button
                    variant="outline"
                    className="w-full flex items-center justify-center gap-2 h-10 px-4 py-2"
                    onClick={handleGoogleSignIn}
                  >
                    <GoogleIcon />
                    <span>Continue with Google</span>
                  </Button>

                  <Button
                    variant="outline"
                    className="w-full flex items-center justify-center gap-2 h-10 px-4 py-2"
                    onClick={handleMagicLinkSignIn}
                    disabled={loading}
                  >
                    <span>Sign in with Magic Link</span>
                  </Button>
  
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <span className="w-full border-t" />
                    </div>
                    <div className="relative flex justify-center text-xs uppercase">
                      <span className="bg-background px-2 text-muted-foreground">
                        Or continue with
                      </span>
                    </div>
                  </div>
  
                  <form onSubmit={handleEmailSignIn} className="space-y-4">
                    <div className="space-y-2">
                      <Input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="space-y-2">
                      <Input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>
                    {error && (
                      <p className="text-sm text-red-500">{error}</p>
                    )}
                    <Button type="submit" className="w-full" disabled={loading}>
                      {loading ? 'Signing in...' : 'Sign In'}
                    </Button>
                  </form>
  
                  <p className="text-center text-sm text-muted-foreground">
                    Don't have an account?{' '}
                    <Link
                      to="/signup"
                      className="text-primary underline-offset-4 hover:underline"
                    >
                      Sign up
                    </Link>
                  </p>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </AuthLayout>
  )
}
