import { useRef, useState } from 'react'; // Added useState
import { useIframeMessaging } from '@/lib/hooks/useIframeMessaging';
import { config } from '@/lib/config';

interface ChatFrameProps {
  agentId: string;
  agentName: string;
  sessionId: string;
  onConfigSent?: () => void;
}

export function ChatFrame({ agentId, agentName, sessionId, onConfigSent }: ChatFrameProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isLoading, setIsLoading] = useState(true); // Added loading state
  useIframeMessaging(iframeRef, onConfigSent);
  const chatUrl = `${config.chatAppURL}?id=${agentId}&agentName=${encodeURIComponent(agentName)}&sessionId=${sessionId}&botIdPrefix=${agentId}&mfe=true`;

  const handleIframeLoad = () => { // Added load handler
    setIsLoading(false);
  };

  return (
    // Added relative positioning to parent div
    <div className="relative flex-grow rounded-lg border bg-background overflow-hidden"> {/* Allow frame to grow vertically */}
      {/* Added conditional loading indicator */}
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-background z-10">
          {/* Added text-xl for larger font */}
          <p className="text-xl">Loading Assistant...</p> {/* Or use a spinner component */}
        </div>
      )}
      <iframe
        ref={iframeRef}
        src={chatUrl}
        // Conditionally apply opacity and add transition
        className={`h-full w-full border-none ${isLoading ? 'opacity-0' : 'opacity-100 transition-opacity duration-300'}`}
        title={`Chat with ${agentName}`}
        allow="microphone; camera"
        sandbox="allow-scripts allow-same-origin allow-forms allow-popups allow-modals"
        // Removed loading="lazy" as we handle loading state manually
        referrerPolicy="origin"
        onLoad={handleIframeLoad} // Added onLoad handler
      />
    </div>
  );
}
